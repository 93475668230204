import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"
import Button from '@material-ui/core/Button';
import PrevIcon from '@material-ui/icons/ChevronLeft';
import NextIcon from '@material-ui/icons/ChevronRight';

import { makeStyles } from '@material-ui/core/styles';
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) => {
  const posts = data.allWpPost.nodes
  const classes = useStyles();

  if (!posts.length) {
    return (
      <Layout isHomePage>
        <Seo title="Our Blog" />
        <section className="alignwide">
          <h1 className={classes.pageHeading}>Our Blog<span className="light-green-text">.</span></h1>
          <p>
            No blog posts found.
          </p>
        </section>
      </Layout>
    )
  }

  return (
    <Layout isHomePage>
      <Seo title="Our Blog" />
      <section className="alignwide">
        <h1 className={classes.pageHeading}>Our Blog<span className="light-green-text">.</span></h1>
        <ol className={classes.postList}>
          {posts.map(post => {
            const title = post.title
            const authorName = post.author.node.firstName ? `${post.author.node.firstName} ${post.author.node.lastName}` : post.author.node.name
            return (
              <li key={post.uri}>
                <article
                  className={classes.postItem}
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <div className={classes.postContentCol}>
                    <header>
                      <h4 className={classes.heading}>
                        <Link to={post.uri} itemProp="url" className={classes.postLink}>
                          <span itemProp="headline">{parse(title)}</span>
                        </Link>
                      </h4>
                      <span className={classes.authorName}>{authorName}</span>
                      <span className={classes.separator}>•</span>
                      <span className={classes.postDate}>{post.date}</span>
                    </header>
                    <section itemProp="description" className={classes.description}>{parse(post.excerpt)}</section>
                  </div>
                  <div className={classes.postImageCol}>
                    {post.featuredImage &&
                      <img src={post.featuredImage.node.sourceUrl} alt={title} className={classes.postImage}/>
                    }
                  </div>
                </article>
              </li>
            )
          })}
        </ol>
        <div className={classes.pagination}>
          {previousPagePath && (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<PrevIcon />}
              component={Link}
              to={previousPagePath}
              className={classes.paginationBtn}
            >
              Previous page
            </Button>
          )}
          {nextPagePath && (
            <Button
              variant="outlined"
              color="primary"
              endIcon={<NextIcon />}
              component={Link}
              to={nextPagePath}
              className={classes.paginationBtn}
            >
              Next page
            </Button>
          )}
        </div>
      </section>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      filter: {categories: {nodes: {elemMatch: {slug: {eq: "blog"}}}}}
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            sourceUrl
          }
        }
        author {
          node {
            name
            firstName
            lastName
          }
        }
      }
    }
  }
`

const useStyles = makeStyles((theme) => ({
  pageHeading: {
    paddingTop: 48,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 24,
      fontSize: '2rem'
    },
  },
  postList: {
    listStyle: 'none',
    paddingLeft: 0
  },
  postItem: {
    display: 'flex',
    marginBottom: '2.4rem'
  },
  postContentCol: {
    paddingRight: 20,
    flexGrow: 1
  },
  heading: {
    fontSize: '1.6rem',
    fontWeight: 500,
    margin: '0px 0px 8px 0',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.1rem',
      lineHeight: '1.5rem'
    },
  },
  postLink: {
    color: 'black !important',
    textTransform: 'none',
    textDecoration: 'none',
    '&:hover': {
      color: '#53c07d !important',
    }
  },
  postImageCol: {
    width: 300,
    [theme.breakpoints.down('xs')]: {
      width: 100,
    },
  },
  postImage: {
    width: 300,
    borderRadius: 6,
    [theme.breakpoints.down('xs')]: {
      width: 100,
    },
  },
  description: {
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
  authorName: {
    fontSize: 14,
    color: '#0d850d',
    textTransform: 'capitalize'
  },
  separator: {
    color: '#c8c8c8',
    margin: '0 8px',
    fontSize: 12
  },
  postDate: {
    color: 'grey',
    fontSize: 12
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20
  },
  paginationBtn: {
    margin: '0 10px'
  }
}));